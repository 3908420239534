import React from 'react';
import '../css/about.css';
let name="About Us"

export default function About() {
  return (
    <>

    <div className='inner-banner'>
       <div className='title'><h1>{name}</h1></div>
     <img  className="img" src={'assets/images/about-banner.jpg'} alt=""/>
    </div>
    <section className='about-sec'>
   <div className='container'>
      <div className='row'>
        <div className='col-md-7'>
         <div className='intro-box'>
         <h2><span>OUR COMPANY</span><br/> About Our Company</h2>
         <p>We are a career services company, we provide all the resources, services, and tools you need right out of school till your retirement, we help professionals use their career stories to land job interviews by writing functional CVs and provide them with tools to navigate the world of work through various coaching and training services.</p>
         </div>

        </div>
        <div className='col-md-5'>
         <div className='img-box'>
         <img  className="img" src={'assets/images/about.png'} alt=""/>


         </div>


        </div>
      </div>
   </div>
   </section>
  <section className='ser-sec'>
  <div className='container'>
    <div className="row"><div className='col-md-12'><div className='title-m'><h2><span>Who Should </span><br/>Use Our Services?</h2></div></div></div>
    <div className="row justify-content-center"><div className='col-md-10'><p>Whether through our services to employers and employees, or our customized educational services to students from different countries in the world, Fasthire wants to ensure we help our clients to navigate the tides of the academics, and corporate world.</p></div></div>
  </div>
  <div className="container">
<div className='row'><div className='col-md-12'>
  <div className='timline-bx'>
<div className="timeline-block timeline-block-right">
   <div className="marker"></div>
   <div className="timeline-content">
      <h3>Jobseeker</h3>
   </div>
</div>

<div className="timeline-block timeline-block-left">
   <div className="marker"></div>
   <div className="timeline-content">
      <h3>Employer</h3>
   </div>
</div>

<div className="timeline-block timeline-block-right">
   <div className="marker"></div>
   <div className="timeline-content">
      <h3>Corporate leader</h3>
   </div>
</div>

<div className="timeline-block timeline-block-left">
   <div className="marker"></div>
   <div className="timeline-content">
      <h3>Entrepreneur</h3>
      </div>
</div>

<div className="timeline-block timeline-block-right">
   <div className="marker"></div>
   <div className="timeline-content">
      <h3>Student</h3></div>
</div>
<div className="timeline-block timeline-block-left">
   <div className="marker"></div>
   <div className="timeline-content">
      <h3>Sales professional</h3>
      </div>
</div>
</div>
</div>
</div></div>
  </section>
    </>
  )
}
