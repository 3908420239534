import React from 'react'
import { Link } from 'react-router-dom';
import '../css/personal-statement.css';
export default function LinkedinOptimization() {
  return (
    <>
          <section className='banner-sec'>
          
          <div className='container'>
             <div className='row start-end'>
             <div className='col-md-6'>
               <div className='banner-text'>
                  <h1>Let's write a professional LinkedIn profile for you</h1>
                  <p>LinkedIn is the world's most used social networking platform. It is a place for branding, networking, job search, career growth, and prospecting/marketing.</p>
                  <Link to='/' className='banner-btn'>Optimize My LinkedIn</Link>
               </div>
   
   
             </div>
             <div className='col-md-6'>
               <div className='image-b'>
             <img  className="img" src={'assets/images/profiel.png'} alt=""/>
             </div>
             </div>
             </div>
   
          </div>
   
        </section>
        <section className='sec-contant'>
              <div className='container'>
              <div className="d-flex justify-content-center text-center"><div className='col-md-6'><div className='title-t'><h2>Why Hire Our Professional LinkedIn Profile Writing Service?</h2>
               
                </div></div></div>
   <div className='row'>
   <div className='col-md-4'>
     <div className='l-box'>
      
       <h3>Career expert</h3>
       <p>We are experts in our field. We've been successfully delivering career progress for our clients through our different channels </p>
     </div>
   </div>
   <div className='col-md-4'>
   <div className='l-box'>
     
       <h3>Research Advantage</h3>
       <p>We work with tools that help us easily research your industry, and discover what people are searching for in your niche.</p>
     </div>
   </div>
   <div className='col-md-4'>
   <div className='l-box'>
      
       <h3>Jobseeker</h3>
       <p>We have years of experience working with recruiters and employers. We understand what they want to see from your profile.</p>
     </div>
   </div>
   <div className='col-md-4'>
     <div className='l-box'>
      
       <h3>Fast delivery</h3>
       <p>We have one of the fastest turnarounds amongst the other profile writing services. </p>
     </div>
   </div>
   <div className='col-md-4'>
   <div className='l-box'>
     
       <h3>100% customer support</h3>
       <p>We support you even after we're done with your work.</p>
     </div>
   </div>
 
   </div>
              </div>
   
        </section>
        <section className='mid-sec'>
            <div className='container'>
               <div className='row'>
                 <div className='col-md-12'>
                  <div className='contant-b'>
                   <h2>What Is LinkedIn Profile Optimization &amp; Why Should You Optimize Your LinkedIn Profile?</h2>
                   <p>LinkedIn optimization is a way of aligning your profile to suit your branding. This gives you a unique image and value; thereby, positioning you for career opportunities.</p>
                   <p>So, how can you build a good LinkedIn profile?</p></div>
                 </div>

               </div>
               <div className='row'>
                 <div className='col-md-12'>
                 <div className='contant-b'>
                  <h2>What You Will Get:</h2>
                  <ul>
                     <li>1. Create a strong first impression</li>
                    <li>2. Stand out in your niche as a thought-leader/expert</li>
                    <li>3. Generate more traffic to your profile</li>
                    <li>4. Get the job of your dream</li>
                    <li>5. Advance your career</li>
                  </ul></div>
                 </div>
               </div>
            </div>

        </section>
        <section className='pricing-sec'>
                <div className='container'>
                  <div className='title-h'>
                    <h2><span>Pricing</span><br/>LinkedIn Profile Development Packages</h2>
                  </div>
                </div>
                <div className='container'>
                  <div className='row'>
                <div className='col-md-4'>
                 <div className='price-box'>
                   <ul>
                    <li>Bronze Package</li>
                    <li>(Entry-level professionals): </li>
                    <li>2-5 Days Delivery | 2 Revisions</li>
                   </ul>
                  <h5>#7,000 ($35)</h5>
                 </div>

                </div>
                <div className='col-md-4'>
                 <div className='price-box'>
                   <ul>
                    <li>Bronze Package</li>
                    <li>(Entry-level professionals): </li>
                    <li>2-5 Days Delivery | 2 Revisions</li>
                   </ul>
                  <h5>#7,000 ($35)</h5>
                 </div>

                </div>
                <div className='col-md-4'>
                 <div className='price-box'>
                   <ul>
                    <li>Bronze Package</li>
                    <li>(Entry-level professionals): </li>
                    <li>2-5 Days Delivery | 2 Revisions</li>
                   </ul>
                  <h5>#7,000 ($35)</h5>
                 </div>
                 </div>
                </div>
                </div>
        </section>
        <section className='faq-sec'>
           <div className='container'>
            <div className='row'>
             <div className='col-md-6'><img  className="img" src={'assets/images/what-i-do.png'} alt=""/></div>
             <div className='col-md-6'>
                  <div className='faq-box'>
                  <div className="accordion" id="accordionfaq">
     <div className="accordion-item">
       <h2 className="accordion-header" id="headingOne">
         <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
         Steps We Follow to Write Your Personal Statement:
         </button>
       </h2>
       <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionfaq">
         <div className="accordion-body">
          <p>When we write or review your personal statement, we understand that you have concerns about plagiarism. That's why we run your work through our plagiarism checker tool and send you the result of the Check.</p>
          <h4>Here are the steps we follow:</h4>
          <ol>
          <li>Get your details and the details of your application</li>
          <li>Ask you follow up questions based on the details we've gathered about your personal, academic, and work experience.</li>
          <li>Review/write based on the information we've gathered about you.</li>
          <li>Run the written SOP through grammar and plagiarism checks.</li>
          <li>Send you the first preview copy for your consideration.</li>
          <li>Fix a time with you to discuss the work done. We use this medium to explain our approach, ask for your take; if you have adjustments or additions.</li>
          <li>ake the necessary adjustments and send the second preview copy.</li>
          <li>Conclude on your work after you proofread it.</li>
   
          </ol>
         </div>
       </div>
     </div>
     <div className="accordion-item">
       <h2 className="accordion-header" id="headingTwo">
         <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
         Extra: Personal Statement Writing Tips
         </button>
       </h2>
       <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionfaq">
         <div className="accordion-body">
           <ol>
          <li>Have a Plot
          <ul>
            <li>Before you start writing your statement, develop a plot for your approach.</li>
         </ul> </li> 
   <li>Start with a Hook Story
   <ul><li>Your first paragraph should have a strong hook/convincing opening</li></ul>
   </li>
   <li>Show Your interest in the Course
   <ul><li>Why have you chosen this course?</li></ul></li>
   <li>Build a Link
   <ul><li>Link your interests, experience, and education to the course</li></ul></li>
   <li>State Your Whys
   <ul><li>Give good reasons why you've chosen the country and why you think the University is the perfect place for your studies.</li></ul></li>
   <li>What next?
   <ul><li>State clearly what you would like to do after your studies?</li></ul></li>
   <li>What is in it for them?
   <ul><li>What kind of contribution or commitment will you make to the institution after your studies?</li></ul></li>
   <li>Prepare Your Personal Statement Days Before Deadline
   <ul><li>This will enable you to proof it thoroughly and also send it to friends and experts for their contributions.</li></ul></li>
   <li>Run Plagiarism and Grammar Checks
   <ul><li>Don't leave it to chance. Though you wrote it yourself, conduct plagiarism and grammar checks.</li></ul></li>
   
           </ol>
         </div>
       </div>
     </div>
   
   </div>
   
                  </div>
             </div>
            </div>
   
   
           </div>
   
   
        </section>
    </>
  )
}
