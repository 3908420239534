import React from 'react';
import '../css/contact-us.css';
import {useFormik } from 'formik';
import Swal from 'sweetalert2';
import * as Yup from 'yup';
let name="Contact US";
export default function ContactUs() {
	
	//const [subEmail, setSubEmail] = useState('')
	const formik = useFormik({
		initialValues: {

			name: '',
			email: '',
			mobile: '',
			comSubject: '',
			msg: '',
			
		},
		validationSchema: Yup.object({
			name: Yup.string()
				.max(50, 'username must be less than or equal to 10 char')
				.required("Please Enter Name"),

			mobile: Yup.string()
				.required("Please Enter Mobile No."),

			email: Yup.string()
				.required("Please Enter Email Id ")
				.email("Please provide a valid email id"),

				comSubject: Yup.string()
				.required("Please Select Your Subject"),

			msg: Yup.string()
				.required("Enter Your Massage"),

		}),
		onSubmit: (value, onSubmitProps) => {
			console.log('all values : ',value);
			console.log('onSubmitProps : ',onSubmitProps);
			let formData = new FormData();
			formData.append('name', value.name);
			formData.append('email', value.email);
			formData.append('mobile', value.mobile);
			formData.append('comSubject', value.comSubject);
			formData.append('msg', value.msg);

			// let result = fetch('http://localhost/jobs/mailer.php', {
                 let result = fetch('https://rightjobsavenue.com/mailer.php', {
				method: 'POST',
				body: formData,
				// headers: {
				//   // 'Content-type': 'application/json; charset=UTF-8',
				//   'Content-type': 'multipart/form-data; ',
				// },
			})
				.then((response) => response.json())
				.then((data) => {
					if (data === '1') {
						Swal.fire({
							//position: 'top-end',
							icon: 'success',
							title: data.msg,
							showConfirmButton: false,
							timer: 1500
						})
						onSubmitProps.setSubmitting(false);
						onSubmitProps.resetForm();
						('textarea').text("");


					} else {
						Swal.fire({
							//position: 'top-end',
							icon: 'success',
							title: data.data,
							showConfirmButton: false,
							timer: 1500
						})

						onSubmitProps.setSubmitting(false);
						onSubmitProps.resetForm();
						('textarea').text("");
					}

					
				})
				.catch((err) => {
					console.log(err.message);
				});

		}
	})
  return (
    <>
      <div className='inner-banner'>
       <div className='title'><h1>{name}</h1></div>
     <img  className="img" src={'assets/images/contact-banner.jpg'} alt=""/>
    </div>
    <section className='contact-sec'>
      <div className="container">
<div className='row'>
  <div className='col-md-6'>
   <div className='contact-box'>
   <h2>Our Office</h2>
   <ul>
     <li><i className='icon icon-map-locator'></i><span>Infinite Cross, International Noel Street Los Alamitos, CA 90720, United States Of Amarica</span></li>
     <li><i className='icon icon-envelope'></i><span><button onClick={() => window.location = 'mailto:info@rightavenue.com'}>info@rightavenue.com</button></span></li>
     <li><i className='icon icon-phone'></i><span><button onClick={() => window.location = 'tel:+2349071827545'}>+234 907 182 7545</button></span></li>
  
   </ul>


   </div>


  </div>
  <div className='col-md-6'>
   <div className='form-box-c'>
    <h3>Feel free to contact us!</h3>
    <form  onSubmit={formik.handleSubmit}>
   <div className="mb-3">
   
   <input type='text' className="form-control" name='name' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.name} placeholder="Name" />
													{formik.touched.name && formik.errors.name && <div className="form-alert"><i className='icon icon-alert'></i>{formik.errors.name} </div>}

													<span className="glyphicon form-control-feedback" aria-hidden="true"></span>
													<div className="help-block with-errors"></div>
   </div>
   <div className="mb-3">
   <input type='text' className="form-control" name='email' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.email} placeholder="Email" />
													{formik.touched.email && formik.errors.email && <div className="form-alert"><i className='icon icon-alert'></i>{formik.errors.email} </div>}


													<span className="glyphicon form-control-feedback" aria-hidden="true"></span>
													<div className="help-block with-errors"></div>
   </div>
   <div className="mb-3">
   <input type='text' className='form-control'  name='mobile' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.mobile} placeholder="Mobile" />
   {formik.touched.mobile && formik.errors.mobile && <div className="form-alert"><i className='icon icon-alert'></i>{formik.errors.mobile} </div>}
   </div>
   <div className="mb-3">
   <select name='comSubject' id='comSubject' className='form-select' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.comSubject}>
		<option value="0">Select an Inquiry</option>
		<option value="Employer Subscriptions">Recruiter - Subscriptions</option>
		<option value="Employer - Login Passwords">Recruiter - Login Passwords</option>
		<option value="Employer - Subscription Cancellation">Recruiter - Subscription Cancellation</option>
		<option value="Employer - Specialty Company Listing">Specialty Company Listing</option>
		<option value="Employer - Other">Recruiter - Other</option>
		<option value="Job Seeker - Resume Posting Issues/Problems">Candidate - Resume Posting Issues/Problems</option>
		<option value="Job Seeker - Login Passwords">Candidate - Login Passwords</option>
		<option value="Job Seeker - FETCH Job Alerts">Candidate - FETCH Job Alerts</option>
		<option value="Job Seeker - Other">Candidate - Other</option>
		<option value="General - Support">General - Support</option>
		<option value="General - Business Tie-Up">General - Business Tie-Up</option>
		<option value="General - Advertisements">General - Advertisements</option>
		<option value="General - Events, Conferences">General - Events, Conferences</option>
		<option value="Merger &amp; Acquisition">Merger &amp; Acquisition</option>
		<option value="Training Partner">Training Partner</option>
		<option value="Training Inquiry">Training Inquiry</option>
		<option value="CCPA - Request: Do Not Sell My Info / Delete My Account">CCPA - Request: "Do Not Sell My Info" / Delete My Account</option>

	</select>
   {formik.touched.comSubject && formik.errors.comSubject && <div className="form-alert"><i className='icon icon-alert'></i>{formik.errors.comSubject} </div>}
   </div>
   <div className="mb-3">
   <textarea  onChange={formik.handleChange} onBlur={formik.handleBlur}  className="form-control" id="message" name="msg" placeholder="Message" value={formik.values.msg} />

													{/* <input type='text' className="form-control" name='message' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.name} placeholder="name" /><br></br> */}
													{formik.touched.msg && formik.errors.msg && <div className="form-alert"><i className='icon icon-alert'></i>{formik.errors.msg} </div>}
   </div>
   <div className="mb-0"><button className="btn btnform" type='submit'>Submit</button></div>
   </form>
   </div>


  </div>
</div>


      </div>
       

    </section>

<section className='map-sec'>
<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d253682.6326917232!2d3.143872569337362!3d6.548035698101199!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b8b2ae68280c1%3A0xdc9e87a367c3d9cb!2sLagos%2C%20Nigeria!5e0!3m2!1sen!2sin!4v1664250167292!5m2!1sen!2sin" width={1000} height={500} title='map'></iframe>
</section>
    </>
    
  )
}


