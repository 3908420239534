import React from 'react'
import { useState } from 'react';
//import logo from './assets/images/logo.png';

import { NavLink,Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
export default function Header() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
    
    <header className="main-hd animated">
    <nav className="navbar navbar-expand-lg">
      <div className="container-fluid ">
        <div className="desktop-dp d-flex justify-content-between align-itams-center">
             <NavLink className="navbar-brand" to="/"><img src="/assets/images/logo.png" alt="My logo" /></NavLink >
           <div className='d-flex d-topnav'>
              <div className="navbar-collapse  my-menu">
             
                <ul className="navbar-nav " >
                 <li className="nav-item"> <NavLink exact  activeClassName="active" className="nav-link"  to="/">Home</NavLink > </li>
                 <li className="nav-item"> <NavLink exact  activeClassName="active" className="nav-link"  to="/about">About Us</NavLink > </li>
                 <li className="nav-item dropdown"> <NavLink exact  className="nav-link dropdown-toggle"  to="#">Services</NavLink> 
                    <ul className='dropdown-menu'>
                      <li className="nav-item"> <NavLink exact  activeClassName="active" className="nav-link"  to="/post-job">Post Job</NavLink ></li>  
                       
                    </ul>
                    
                 </li>
                 <li className="nav-item dropdown"> <NavLink exact className="nav-link dropdown-toggle"  to="#">Career Resources</NavLink > 
                 
                 <ul className='dropdown-menu'> 
                       <li className="nav-item"> <NavLink exact  activeClassName="active" className="nav-link"  to="/personal-statement">Personal Statement Writing</NavLink ></li>  
                       <li className="nav-item"> <NavLink exact  activeClassName="active" className="nav-link"  to="/linkedin-Optimization">LinkedIn Optimization</NavLink ></li>  
                       <li className="nav-item"> <NavLink exact  activeClassName="active" className="nav-link"  to="/cv-writing">CV Writing</NavLink ></li>
                       <li className="nav-item"> <NavLink exact  activeClassName="active" className="nav-link"  to="/lette-of-recommendation">Letter of Recommendation</NavLink ></li>
                       <li className="nav-item"> <NavLink exact  activeClassName="active" className="nav-link"  to="/testimonials">Testimonials</NavLink ></li>
                       <li className="nav-item"> <NavLink exact  activeClassName="active" className="nav-link"  to="/CareerAdvice">Career Advice</NavLink ></li>  
                       <li className="nav-item"> <NavLink exact  activeClassName="active" className="nav-link"  to="/Ebooks">Ebooks</NavLink ></li>  
                      
                    </ul>
                 
                 </li>
                 <li className="nav-item"> <NavLink exact  activeClassName="active" className="nav-link"  to="/contact-us">Contact Us</NavLink > </li>
                </ul>
           
                <div className="globle-link top-nav-m"> <NavLink  to="/post-job" className="postjob-btn">Post Job</NavLink ></div>
               
            
            </div>
  
            <div className="globle-link top-l-d"><Button className="btn-login" onClick={handleShow}>Login/Register</Button > <Link  to="/post-job" className="postjob-btn btn">Post Job</Link ></div>
              </div>
              <div className='mobile-topbar'>
                <div  className='d-flex'>
        <div className='mob-login'>
        <div className='avtar-login'></div>
          <Button  variant="btn-login-mobile" onClick={handleShow}>
           <i className='icon icon-avtar'></i>
        </Button >
        
        </div>
          <button className="navbar-toggler bt-menu" type="button" data-bs-toggle="collapse" data-bs-target="#navbarPhone" aria-controls="navbarPhone" aria-expanded="false" aria-label="Toggle navigation"> <span></span> <span></span> <span className="last"></span> </button>
        </div>
        </div>
      </div>
      </div>
      <div className="mOverlay"></div>
    </nav>
  </header>

  <Modal show={show} onHide={handleClose}  centered className='reg-login'>
          <div id="login_box">
        <Modal.Header closeButton>
          <Modal.Title>Login to Right jobs avenue</Modal.Title> 
        </Modal.Header>
        <Modal.Body>
  
          <form>
          <div className="mb-4">
            <div className='formrow'>
    <input type="email" value="" className='form-control' placeholder='Email' />
    </div>
   </div>
  
   <div className="mb-4">
   <div className='formrow'>
    <input type="password" value="" className='form-control' placeholder='Password' />
    <Link  to="#" className='toggle-password'><i className='icon icon-hide-p'></i></Link>
</div>
   </div>
   <div className="mb-4">
   <div className='formrow row'>
    <div className='col-6'>
      <input type="checkbox" id="keep-login"/>
      <label for="keep-login">Keep me signed in	</label>
      </div>
      <div className='col-6 text-end'><Link to="">Forgotten password?</Link>
      </div>
</div>
   </div>

       <Modal.Footer className='justify-content-center'>
          
       <Button variant="primary"  className='login-submit' onClick={handleClose}>
            Login
          </Button>
        
       </Modal.Footer>

       <div className="mb-3"><p className='text-center loginp'>Don't you have an account? <Link to='#'>Register</Link></p></div>
          </form>


        </Modal.Body>
        </div>


        <div id="register_box" >
        <Modal.Header closeButton>
          <Modal.Title>Login to Right jobs avenue</Modal.Title> 
        </Modal.Header>
        <Modal.Body>
        <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
  <li class="nav-item" role="presentation">
    <button class="nav-link active" id="pills-Candidate-tab" data-bs-toggle="pill" data-bs-target="#pills-Candidate" type="button" role="tab" aria-controls="pills-Candidate" aria-selected="true"><i className='icon icon-avtar'></i> Candidate</button>
  </li>
  <li class="nav-item" role="presentation">
    <button class="nav-link" id="pills-Employer-tab" data-bs-toggle="pill" data-bs-target="#pills-Employer" type="button" role="tab" aria-controls="pills-Employer" aria-selected="false">Employer</button>
  </li>
 
</ul>
<div class="tab-content" id="pills-tabContent">
  <div class="tab-pane fade show active" id="pills-Candidate" role="tabpanel" aria-labelledby="pills-Candidate-tab">
          <form>
          <div className="mb-4">
            <div className='formrow'>
    <input type="email" value="" className='form-control' placeholder='Email' />
    </div>
   </div>
  
   <div className="mb-4">
   <div className='formrow'>
    <input type="password" value="" className='form-control' placeholder='Password' />
    <Link  to="#" className='toggle-password'><i className='icon icon-hide-p'></i></Link>
</div>
   </div>
   <div className="mb-4">
   <div className='formrow'>
    <input type="password" value="" className='form-control' placeholder='Confirm Password' />
    <Link  to="#" className='toggle-password'><i className='icon icon-hide-p'></i></Link>
</div>
   </div>
   <div className="mb-4">
   <div className='formrow row'>
    <div className='col-12'>
      <input type="checkbox" id="keep-reg"/>
      <label htmlFor="keep-reg">You accept our Terms and Conditions and Privacy Policy</label>
      </div>
     
</div>
   </div>

       <Modal.Footer className='justify-content-center'>
          
       <Button variant="primary"  className='login-submit' onClick={handleClose}>
       Register now
          </Button>
        
       </Modal.Footer>

       <div className="mb-3"><p className='text-center loginp'>Already have an account? <Link to='#'>Login</Link></p></div>
          </form>
</div>
  <div class="tab-pane fade" id="pills-Employer" role="tabpanel" aria-labelledby="pills-Employer-tab">
          <form>
          <div className="mb-4">
            <div className='formrow'>
    <input type="email" value="" className='form-control' placeholder='Email' />
    </div>
   </div>
  
   <div className="mb-4">
   <div className='formrow'>
    <input type="password" value="" className='form-control' placeholder='Password' />
    <Link  to="#" className='toggle-password'><i className='icon icon-hide-p'></i></Link>
</div>
   </div>
   <div className="mb-4">
   <div className='formrow'>
    <input type="password" value="" className='form-control' placeholder='Confirm Password' />
    <Link  to="#" className='toggle-password'><i className='icon icon-hide-p'></i></Link>
</div>
   </div>
   <div className="mb-4">
   <div className='formrow row'>
    <div className='col-12'>
      <input type="checkbox" id="keep-reg"/>
      <label htmlFor="keep-reg">You accept our Terms and Conditions and Privacy Policy</label>
      </div>
     
</div>
   </div>

       <Modal.Footer className='justify-content-center'>
          
       <Button variant="primary"  className='login-submit' onClick={handleClose}>
       Register now
          </Button>
        
       </Modal.Footer>

       <div className="mb-3"><p className='text-center loginp'>Already have an account? <Link to='#'>Login</Link></p></div>
          </form>
</div>
 
</div>




       
  

        </Modal.Body>
        </div>
      </Modal>
  </>
  )
}
