import React from 'react'
import { Link } from 'react-router-dom';
import '../css/personal-statement.css';
export default function Testimonials() {
  return (
    <>
       <section className='banner-sec banner-testi' >
          
          <div className='container'>
             <div className='row start-end'>
             <div className='col-md-6'>
               <div className='banner-text'>
                  <h1>Testimonials</h1>
                  <p>Personal statement is a document used to highlight your academic background, experience, and career goals.</p>
                  <Link to='/' className='banner-btn'>Get Your Personal Statement</Link>
               </div>
   
   
             </div>
             <div className='col-md-6'>
               <div className='image-b-'>
             <img  className="img" src={'assets/images/teasti.png'} alt=""/>
             </div>
             </div>
             </div>
   
          </div>
   
        </section>
<section className='testimonial-sec'>
   <div className='container'>


   </div>


</section>


    </>
  )
}
