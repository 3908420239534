import React from 'react'
import { Link } from 'react-router-dom';
//import '../css/personal-statement.css';
import HomeReview from './homecomponent/HomeReview'
export default function CVWritingService() {
  return (
    <>
         <section className='banner-sec'>
          
          <div className='container'>
             <div className='row start-end'>
             <div className='col-md-6'>
               <div className='banner-text'>
                  <h1>Get your  professional cv/resume re-written by our experts in 2-5 days</h1>
                  <p>Our goal is to develop high-end job documents that increases your chances of making it to the interview stage by 50%.</p>
                  <Link to='/' className='banner-btn'>Re-write Your CV</Link>
               </div>
   
   
             </div>
             <div className='col-md-6'>
               <div className='image-b'>
             <img  className="img" src={'assets/images/sofia-min.png'} alt=""/>
             </div>
             </div>
             </div>
   
          </div>
   
        </section>
        <section className='sec-contant'>
           <div className='container'>
           <div className="d-flex justify-content-center text-center"><div className='col-md-6'><div className='title-t'><h2>What You'll Get</h2>
             </div></div></div>
<div className='row'>
<div className='col-md-4'>
  <div className='l-box'>
   <div className='icon-box'><i className='icon icon-share-circle'></i></div>
    <h3>PROFESSIONAL TOUCH</h3>
    <p>Professional Layout: Easy to read, strategically formatted to industry standards.</p>
  </div>
</div>
<div className='col-md-4'>
<div className='l-box'>
   <div className='icon-box'><i className='icon icon-pie-chart'></i></div>
    <h3>HIGHLIGHT SKILLS</h3>
    <p>Highlight Values: Professionally written to showcase your key achievements and strengths</p>
  </div>
</div>
<div className='col-md-4'>
<div className='l-box'>
   <div className='icon-box'><i className='icon icon-laptop'></i></div>
    <h3>ATS COMPATIBLE</h3>
    <p>Key Words: Your CV will be fine-tuned to speak the language of your the industry you aspire to be in.</p>
  </div>
</div>
<div className='col-md-4'>
<div className='l-box'>
   <div className='icon-box'><i className='icon icon-boost'></i></div>
    <h3>BOOST CONFIDENCE</h3>
    <p>Clear Communications: Language style is used to clearly communicate your details to prospective employers</p>
  </div>
</div>
<div className='col-md-4'>
<div className='l-box'>
   <div className='icon-box'><i className='icon icon-time-management'></i></div>
    <h3>SAVE TIME</h3>
    <p>Correct/Fix Hidden Errors: We correct hidden errors that are popular red flags to potential employers.</p>
  </div>
</div>
<div className='col-md-4'>
<div className='l-box'>
   <div className='icon-box'><i className='icon icon-speed'></i></div>
    <h3>GET HIRED FAST</h3>
    <p>Cover letter: Well written and targeted cover letters that will boost your job application.</p>
  </div>
</div>

</div>
           </div>

     </section>
          
     <HomeReview></HomeReview>

     <section className='pricing-sec'>
                <div className='container'>
                  <div className='title-h'>
                    <h2><span>Pricing</span><br/>LinkedIn Profile Development Packages</h2>
                  </div>
                </div>
                <div className='container'>
                  <div className='row'>
                <div className='col-md-6'>
                 
                  <div className='price-bx'>
                    <p>Entry-level professionals (0 - 2 years)</p>
                    <h3>CV Writing</h3>
                    <ul className='check-list'>
                       <li>Regular Service 2-5 days NGN 5,000</li>
                       <li>Regular Service 2-5 days NGN 5,000</li>
                    </ul>
                    <h3>CV + Cover Letter</h3>
                    <ul>
                       <li>Regular Service 2-5 days NGN 5,000</li>
                       <li>Regular Service 2-5 days NGN 5,000</li>
                    </ul>
                    <ul className='price-list'>
                      <li><label>CV Writing Online Payment</label><button className='price'>Pay N5,000</button><button className='price'>Pay N7,000</button></li>
                      <li><label>CV Writing Online Payment</label><button className='price'>Pay N5,000</button><button className='price'>Pay N7,000</button></li>
                    </ul>
                  </div>
                </div>
                <div className='col-md-6'>
                 
                 <div className='price-bx'>
                   <p>Entry-level professionals (0 - 2 years)</p>
                   <h3>CV Writing</h3>
                   <ul className='check-list'>
                      <li>Regular Service 2-5 days NGN 5,000</li>
                      <li>Regular Service 2-5 days NGN 5,000</li>
                   </ul>
                   <h3>CV + Cover Letter</h3>
                   <ul>
                      <li>Regular Service 2-5 days NGN 5,000</li>
                      <li>Regular Service 2-5 days NGN 5,000</li>
                   </ul>
                   <ul className='price-list'>
                     <li><label>CV Writing Online Payment</label><button className='price'>Pay N5,000</button><button className='price'>Pay N7,000</button></li>
                     <li><label>CV Writing Online Payment</label><button className='price'>Pay N5,000</button><button className='price'>Pay N7,000</button></li>
                   </ul>
                 </div>
               </div>
               <div className='col-md-6'>
                 
                 <div className='price-bx'>
                   <p>Entry-level professionals (0 - 2 years)</p>
                   <h3>CV Writing</h3>
                   <ul className='check-list'>
                      <li>Regular Service 2-5 days NGN 5,000</li>
                      <li>Regular Service 2-5 days NGN 5,000</li>
                   </ul>
                   <h3>CV + Cover Letter</h3>
                   <ul>
                      <li>Regular Service 2-5 days NGN 5,000</li>
                      <li>Regular Service 2-5 days NGN 5,000</li>
                   </ul>
                   <ul className='price-list'>
                     <li><label>CV Writing Online Payment</label><button className='price'>Pay N5,000</button><button className='price'>Pay N7,000</button></li>
                     <li><label>CV Writing Online Payment</label><button className='price'>Pay N5,000</button><button className='price'>Pay N7,000</button></li>
                   </ul>
                 </div>
               </div>
               <div className='col-md-6'>
                 
                 <div className='price-bx'>
                   <p>Entry-level professionals (0 - 2 years)</p>
                   <h3>CV Writing</h3>
                   <ul className='check-list'>
                      <li>Regular Service 2-5 days NGN 5,000</li>
                      <li>Regular Service 2-5 days NGN 5,000</li>
                   </ul>
                   <h3>CV + Cover Letter</h3>
                   <ul>
                      <li>Regular Service 2-5 days NGN 5,000</li>
                      <li>Regular Service 2-5 days NGN 5,000</li>
                   </ul>
                   <ul className='price-list'>
                     <li><label>CV Writing Online Payment</label><button className='price'>Pay N5,000</button><button className='price'>Pay N7,000</button></li>
                     <li><label>CV Writing Online Payment</label><button className='price'>Pay N5,000</button><button className='price'>Pay N7,000</button></li>
                   </ul>
                 </div>
               </div>
                </div>
                </div>
        </section>

    </>
  )
}
