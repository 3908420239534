import React from 'react'
import './App.css';

import Header from './mycomponents/Header';
import Footer from './mycomponents/Footer';

import {BrowserRouter, Route , Switch } from 'react-router-dom';
import Home from './mycomponents/Home';
import About from './mycomponents/About';
import ContactUs from './mycomponents/ContactUs';
import PersonalStatement from './mycomponents/PersonalStatement';
import LinkedinOptimization from './mycomponents/LinkedinOptimization';
import CVWriting from './mycomponents/CVWritingService';
import LetteRecommendation from './mycomponents/LetteRecommendation';
import Testimonials from './mycomponents/Testimonials';
import CareerAdvice from './mycomponents/CareerAdvice';
import Ebooks from './mycomponents/Ebooks';
import Advertise from './mycomponents/Advertise';
import PostJob from './mycomponents/PostJob';
import ScrollToTop from './helper/ScrollToTop';
function App() {
  return (
    <>
       <BrowserRouter>
       <ScrollToTop/>
        <Header/>
       
        <Switch>
          
          <Route exact path="/" component={Home}/>
          <Route path="/about" component={About}/>
          <Route path="/personal-statement" component={PersonalStatement}/> 
          <Route path="/linkedin-Optimization" component={LinkedinOptimization}/> 
          <Route path="/cv-writing" component={CVWriting}/> 
          <Route path="/lette-of-recommendation" component={LetteRecommendation}/> 
          <Route path="/testimonials" component={Testimonials}/> 
          <Route path="/career-advice" component={CareerAdvice}/> 
          <Route path="/ebooks" component={Ebooks}/> 
          <Route path="/advertise" component={Advertise}/> 
          <Route path="/testimonials" component={Testimonials}/> 
          <Route path="/contact-us" component={ContactUs}/> 
          <Route path="/post-job" component={PostJob}/>            
        </Switch>  
         <Footer/>
         </BrowserRouter>
    </>
  );
}

export default App;
