import React from 'react'
import { Link } from 'react-router-dom';
import '../css/personal-statement.css';
export default function LetteRecommendation() {
  return (
    <>
          <section className='banner-sec'>
          
          <div className='container'>
             <div className='row start-end'>
             <div className='col-md-6'>
               <div className='banner-text'>
                  <h1>Let's write your letter of recommendation</h1>
                  <p>A recommendation letter is a written document by a referee that provides information about your skills, outstanding performances, and capabilities. It also gives details of your character, values, and goals that make you qualified and fit for a position.</p>
                  <Link to='/' className='banner-btn'>Write My Letter of Recommendation</Link>
               </div>
   
   
             </div>
             <div className='col-md-6'>
               <div className='image-b'>
             <img  className="img" src={'assets/images/profiel.png'} alt=""/>
             </div>
             </div>
             </div>
   
          </div>
   
        </section>
<section className='sec-box'>
       <div className='container'>
         <div className='row'>
         <div className='col-md-4'>
          <div className='rec-img-box'>
            <img src={'assets/images/Recommendation-letter-bro-1-min-768x768.png'} alt="" />
          </div>

         </div>
         <div className='col-md-8'>
             <div className='right-contant'>
              <h2>Why Is Letter Of Recommendation Important?</h2>
              <p>Letter of Recommendation is crucial and relevant, because, asides from your credentials and other documents you present, it serves as a direct and reliable testimony of your character, achievements, and skills. Hence, this is why you have to search carefully for the right person to help you with the letter. Note, it has to be someone who can pleasantly present you in a credible and positive light, such that when the admission board or a recruiter goes through the letter, they would find you a candidate worth the consideration.</p>
              <p>Sometimes, people have been considered almost solely on their letter of recommendations written about them. For some schools, it’s a primary requirement, because they don’t just want to consider you based on your credentials alone, they also need to be convinced about your character and values. The same also goes for a recruiter. A letter from your former employer can go a long way in convincing the present employer of your credibility.</p>

             </div>

         </div>

         </div>
         <div className='row'>

         <div className='col-md-8'>
             <div className='right-contant leftside'>
              <h2>Why Is Letter Of Recommendation Important?</h2>
              <p>Letter of Recommendation is crucial and relevant, because, asides from your credentials and other documents you present, it serves as a direct and reliable testimony of your character, achievements, and skills. Hence, this is why you have to search carefully for the right person to help you with the letter. Note, it has to be someone who can pleasantly present you in a credible and positive light, such that when the admission board or a recruiter goes through the letter, they would find you a candidate worth the consideration.</p>
              <p>Sometimes, people have been considered almost solely on their letter of recommendations written about them. For some schools, it’s a primary requirement, because they don’t just want to consider you based on your credentials alone, they also need to be convinced about your character and values. The same also goes for a recruiter. A letter from your former employer can go a long way in convincing the present employer of your credibility.</p>

             </div>

         </div>
         <div className='col-md-4'>
          <div className='rec-img-box'>
            <img src={'assets/images/Recommendation-letter-pana-min-768x768.png'} alt="" />
          </div>

         </div>
         </div>

       </div>
</section>
<section className='pricing-sec'>
                <div className='container'>
                  <div className='title-h'>
                    <h2><span>Pricing</span><br/>Letter Of Recommendation Fee</h2>
                  </div>
                </div>
                <div className='container'>
                  <div className='row'>
           
               
               <div className='col-md-4'>
                 
                 <div className='price-bx'>
                   <p>Letter of recommendation fee</p>
                   <h3>#5,000 ($30)</h3>
                 
                   <ul className='price-list'>
                     <li><button className='price'>Pay N5,000</button><button className='price'>Pay N7,000</button></li>
                     
                   </ul>
                 </div>
               </div>
              
                </div>
                </div>
        </section>


    </>
  )
}
