import React from 'react'
import OwlCarousel from 'react-owl-carousel';

const options = {
  margin: 10,
  responsiveClass: true,
  nav: true,
  autoplay: true,
  loop:true,
   navText: ["Prev", "Next"],
  smartSpeed: 1000,
  responsive: {
      0: {
          items: 1,
          dots:true,

      },
      400: {
          items: 1,
      },
      600: {
          items: 2,
      },
      700: {
          items: 2,
      },
      1000: {
          items:3,
      }
  },
};
export default function HomeReview() {
  return (
    <>
    <section className='review-sec'>
<div className='title-top'><h2>Testimonials From Our Customers</h2></div>
<div className='subtitle'><p>People who use Fasthire service always recommend their friends and family, your story will be the same if you give us a try.</p></div>

<div className='container'>
      <OwlCarousel className='owl-theme sr-slider'{...options}>
    <div className='item'>
        <div className='card'>
        <div className='icon-b'><img  className="img" src={'assets/images/funmii-min.jpg'} alt=""/></div>
   
    <div className='contant-bx'>
        <h2>Funmilayo Lawal <br/><small>(Entrepreneur, June, 2021)</small></h2>
        <p>I discovered Fasthire service via ad and when I was about to contact them, my first thought was" hope I won't be scammed". Not only was I not scammed but the service I received in writing my Statement of Purpose was swift,  professional and tailored to incorporate my academic and career background.</p>
       
    </div>
    </div>
    </div>
    <div className='item'>
        <div className='card'>
        <div className='icon-b'><img  className="img" src={'assets/images/ayot-min-1.jpg'} alt=""/> </div>
   
    <div className='contant-bx'>
        <h2>Ayotunde Ifeozo (Assistant Manager Legal Services &amp; Corporate Compliance February, 2021)</h2>
        <p>I just want to say thank you for your professional service. I'm most impressed and very happy with the LinkedIn optimization. Already I've gotten multiple job applications and the clarity of my recruitment information has been to my advantage. Thank you!</p>
    
    </div>
    </div>
    </div>
    <div className='item'>
        <div className='card'>
        <div className='icon-b'><img  className="img" src={'assets/images/chiomaa-min.jpg'} alt=""/></div>
    
    <div className='contant-bx'>
        <h2>Chioma Umeh</h2>
        <p>I discovered Fasthire service via ad and when I was about to contact them, my first thought was" hope I won't be scammed". Not only was I not scammed but the service I received in writing my Statement of Purpose was swift,  professional and tailored to incorporate my academic and career background.</p>
        
    </div>
    </div>
    </div>
    <div className='item'>
        <div className='card'>
        <div className='icon-b'><img  className="img" src={'assets/images/isaiah.jpg'} alt=""/></div>
    
    <div className='contant-bx'>
        <h2>Timilehin Isiah (I.T Engineer, June 2021)</h2>
        <p>Mr. Richard has been a wonderful person. We met online through Instagram. We discussed and he saw reasons to rewrite my LinkedIn profile and also my CV. Well, it was a big risk to have sent money to someone I do not know where to trace him considering Nigeria factor. 
Nevertheless, I sent some money to him to work on my LinkedIn Profile and he did an excellent job that I now have an interview invite after over 10 months of no invite. I also paid for a CV revamp after the good job on my LinkedIn profile. He did a good job here too. I think we can trust him with our funds and document to do a good job.</p>
        
    </div>
    </div>
    </div>
 
</OwlCarousel>
</div>
</section>
    </>
  )
}
