import React from 'react'
import { Link } from 'react-router-dom';
import '../css/personal-statement.css';
export default function PostJob() {
 // const [formStatus] = React.useState('Submit')
  // const onSubmit = (e) => {
  //   e.preventDefault()
  //   setFormStatus('Submitting...')
  //   const { name, email, message } = e.target.elements
  //   let conFom = {
  //     name: name.value,
  //     email: email.value,
  //     message: message.value,
  //   }
  //   console.log(conFom)
  // }
  return (
    <>
           <section className='banner-sec'>
          
          <div className='container'>
             <div className='row start-end'>
             <div className='col-md-6'>
               <div className='banner-text'>
                  <h1>Free Job Posting</h1>
                  <p>Reach thousands of great candidates in Nigeria with our free job posting plan. With our free job posting plan, you can target jobseekers nationwide in different industries and specializations. Also, we ensure that all jobs listed on our website pass our quality check. Hence, your job is being posted free on a platform that takes pride in listing only quality and verified jobs in Nigeria. To proceed, fill the form below and submit.</p>
                  <Link to='/' className='banner-btn'>Get Your Personal Statement</Link>
               </div>
   
   
             </div>
             <div className='col-md-6'>
               <div className='image-b'>
             <img  className="img" src={'assets/images/fasthire-personal-statement-writing-min.png'} alt=""/>
             </div>
             </div>
             </div>
   
          </div>
   
        </section>

    <section className='post-sec'>

     <div className='container'>
         <div className='row'>
         <div className='col-md-7'>
           <div className='post-form'>
               <h3>Post Your CV</h3>

           <form >
        <div className="mb-3">
          <label className="form-label" htmlFor="name">
            Name
          </label>
          <input className="form-control" type="text" id="name" required />
        </div>
        <div className="mb-3">
          <label className="form-label" htmlFor="email">
            Email
          </label>
          <input className="form-control" type="email" id="email" required />
        </div>
        <div className="mb-3">
          <label className="form-label" htmlFor="message">
            Message
          </label>
          <textarea className="form-control" id="message" required />
        </div>
        <button className="btn btnform" type="submit">
        Submit
          {/* {formStatus} */}
        </button>
      </form>
            </div>     

         </div>
         </div>

     </div>

    </section>

    </>
  )
}
